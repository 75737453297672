.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#ask-hal{
  color:orange;
}

/* Adjust both background color and padding */
.ask-hal-dropdown-item {
  background-color: orange !important; /* Ensure background color is applied */
  padding: 0.25rem 1.5rem !important; /* Adjust padding as needed */
}



/* App.css */
body {
  background-color: #343a40; /* Dark grey, similar to Bootstrap's dark theme */
  color: #ffffff; /* Set text color to white for contrast */
}

.footer {
  background-color: #343a40; /* Same as your Navbar */
  color: #ffffff; /* White text color */
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer */
}

.footer p, .footer span, .footer a { /* Target all text within the footer */
  font-size: 0.9rem; /* Smaller font size for footer text */
}


/* App.css */

/* Style the dropdown menu to match the navbar's dark background */
.navbar-dark .dropdown-menu {
  background-color: #212629 !important; /* Ensure matching background color */
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove shadow */
}

/* Style the dropdown menu items */
.navbar-dark .dropdown-item {
  color: #ffffff; /* White text color for items */
  font-size: 0.9rem;
}

/* Style the dropdown menu items on hover */
.navbar-dark .dropdown-item:hover,
.navbar-dark .dropdown-item:focus {
  background-color: #495057; /* Slightly lighter gray for hovered items */
}

/* Optional: Style the active dropdown menu item */
.navbar-dark .dropdown-item.active {
  background-color: #555; /* Darker gray for active item */
  color: #fff;
}
